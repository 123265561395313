import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import BreguetCategoryBtns from '../../components/preowned/BreguetCategoryBtns'

// markup
const BreguetMarine = () => {
  const data = useStaticQuery(
    graphql`
      query queryBreguetMaringe {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breguet Marine Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breguet Marine watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-breguet-marine-header.png"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breguet Marine Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREGUET WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREGUET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              <br />
              The Breguet Marine watch collection traces back to 1815 when Breguet was anointed the
              official watchmaker for the French Royal Navy; the greatest honor Abraham-Louis
              Breguet could have received. This title paved the way for Breguet’s long road of
              success in their Marine Collection.
              <br />
              <br />
              In becoming the French Navy’s sole horologist, Breguet had a large role to fulfill
              for, really, an entire Nation. In the 1810s, chronometers were vital for traveling
              overseas since they helped calculate the position of ships over long fleets.
              <br />
              <br />
              The Breguet Marine Chronometer was the closest thing the French Navy had to today’s
              GPS and seeing as these fleets could be long and rough, Breguet had to assure it could
              withstand the instability of these ships while keeping accurate time.
              <br />
              <br />
              Since then, the Breguet Marine has been introduced in a variety of different ways
              while still holding traditional Breguet values. The contemporary Breguet Marine
              watches combine the elegant and sports codes of watches. They’re made with durable
              cases and crown protectors but still designed with the craftsmanship and attention to
              detail that Breguet is known for. The perfect watch collection for any watch
              collector.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Breguet Marine Watches</b>
              <br />
              <br />
              The Breguet Marine collection has been evergrowing and is now offered in various
              options of fine metals, and sizes, and containing various complications. The Breguet
              Marine collection today is divided into subcollections, each carrying different
              attributes.
              <br />
              <br />
              Popular Pre-Owned Used Breguet Marine Watch References and Models:
              <ul>
                <li>Pre-Owned Breguet Marine references: 5517, 5817, 5857, 8827, 5527</li>
                <li>Pre-Owned Breguet Marine Hora Mundi references: 5717, 5727, 3700</li>
                <li>Pre-Owned Breguet Marine Dame references: 9518, 9517</li>
                <li>Pre-Owned Breguet Marine Alarme Musicale references: 5547</li>
                <li>
                  Pre-Owned Breguet Marine Chronographe references: 3460, 5827, 8828, 8827, 5829
                </li>
              </ul>
              <br />
              <br />
              Gray and Sons Jewelers is the #1 all-stop shop for all Breguet Marine watch needs. Our
              store is located in South Florida in the city of Surfside across the street from the
              famous Bal Harbour Shops. Aside from buying and selling genuine pre-owned Breguet
              watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. Those looking to ‘get cash for my Breguet watch’ should visit{' '}
              <a href="/">www.grayandsons.com</a> or
              <a href="https://www.sellusyourjewelry.com">Sell Us Your Jewelry </a> to request a
              free watch quote to sell or trade-in for one of ours!
              <br />
              <br />
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Breguet watches, Selling used Breguet
              watches, repairing estate Breguet watches, and trading genuine Breguet timepieces.
              <br />
              <br />
              We have a brick-and-mortar store located in the heart of Miami, Fl near cities like
              Sunny Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut
              Grove, and Coral Gables, Key Biscayne, and many more. On our website, we have LIVE
              representatives Viktoria and Rich who are specialists in buying and selling Breguet
              Marine watches.
              <br />
              <br />
              Our <a href="/chat-with-rich">LIVE CHAT</a> feature allows clients all around the
              nation to make all inquiries about Breguet watches and possibly work out a deal in
              real-time. Gray and Sons Jewelers is the best and most reliable place to buy used
              pre-owned Breguet Marine watches. Our master-trained watchmakers assure every Breguet
              watch in our inventory is certified pre-owned and serviced ready for wear with a
              1-year warranty covering the movement.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/breguet/">
                <button>SHOP BREGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <BreguetCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreguetMarine
